import React, { createContext, useEffect, useState } from "react";

export const AppContext = createContext();
const ContextProviders = ({ children }) => {
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [initialising, setInitialising] = useState(true);

  useEffect(() => {
    const tmpToken = localStorage.getItem("token");
    if (tmpToken) {
      setToken(JSON.parse(tmpToken));
    }

    const tmpUser = localStorage.getItem("user");
    if (tmpUser) {
      setUser(JSON.parse(tmpUser));
    }
    setInitialising(false);
  }, []);
  if (initialising) return;
  return (
    <AppContext.Provider value={{ user, setUser, token, setToken }}>
      {children}
    </AppContext.Provider>
  );
};

export default ContextProviders;
