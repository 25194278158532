import React from 'react'

const Input = ({ label, register, name, errors,placeholder, containerClassName, ...restprops }) => {
    return (
        <div className={`flex flex-col ${containerClassName}`} >
            <label htmlFor={label} className="text-mobile-h5 md:text-tablet-h4 lg:text-h5 font-semibold text-secondary-dark mb-2" >{label}</label>
            <input

                className="pl-2 outline-primary-light font-semibold md:w-[300px] xl:w-354 h-14 md:h-14 xl:h-16 bg-primary-white border-1 lg:border-2 border-primary-light border-solid"
                placeholder={placeholder}
                {...register}
                {...restprops}
            />
            {errors && errors[name] && <p className='text-sm text-error ' >{errors?.[name]?.message}</p>}
        </div>
    )
}

export default Input


