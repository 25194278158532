import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ProtectedLayout from '../layouts/ProtectedLayout';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Login from '../pages/auth/Login';
import ResetPassword from '../pages/auth/ResetPassword';
import SignUp from '../pages/auth/SignUp';
import Home from '../pages/home/Home';
import { FORGOT_PASSWORD, LOGIN, QR_DETAILS, RESET_PASSWORD, SIGN_UP } from '../utils/constants/paths';
import BookQrCodeDetails from '../pages/bookQrDetails/BookQrCodeDetails';

const AppRoute = () => {
    const router = createBrowserRouter([

        {
            path: LOGIN,
            element: <Login />,
        },
        {
            path: SIGN_UP,
            element: <SignUp />,
        },
        {
            path: FORGOT_PASSWORD,
            element: <ForgotPassword />,
        },
        {
            path: RESET_PASSWORD,
            element: <ResetPassword />,
        },
        {
            path: "/",
            element: <ProtectedLayout />,
            children: [{
                path: "/",
                element: <Home />,
            },{
                path: QR_DETAILS,
                element: <BookQrCodeDetails />,
            },]
        },
    ]);

    return (
        <RouterProvider router={router} />

    )
}
export default AppRoute