import {
  CREATE_INDIVIUAL_USER,
  LOG_IN,
  BOOK_QR_DETAILS,
  RESET_PASSWORD,
  ASSIGN_BOOK_QR,
  FORGOT_PASSWORD
} from "./constants/apis";

export async function signup(data) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  // myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Cookie", `jwt=${token}`);

  console.log(data, "script-dta");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      ...data,
      role: "customer"
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(CREATE_INDIVIUAL_USER, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function handleResetPassword(
  password,
  passwordConfirm,
  role,
  token
) {
  let error;
  let response;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const body = JSON.stringify({
    role,
    password,
    passwordConfirm
  });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(`${RESET_PASSWORD}/${token}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function handleLogin(data) {
  let response;
  let error;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({
    email: data.email,
    password: data.password,
    role: data.role,
    from: data.from
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(LOG_IN, requestOptions);
    response = await res.json();
    console.log(response, "response");
    if (response.status !== "fail") {
      localStorage.setItem("token", JSON.stringify(response.token));
      localStorage.setItem("user", JSON.stringify(response.data.user));
    }
  } catch (err) {
    error = await err;
  }

  return { response, error };
}

export async function handleForgotPassword(email) {
  let error;
  let response;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const body = JSON.stringify({
    role: "customer",
    email
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(FORGOT_PASSWORD, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}


export async function getBookQrDetails(qrId) {
  let response;
  let error;

  const myHeaders = new Headers();
  
  myHeaders.append("Content-Type", "application/json");

  const token = JSON.parse(localStorage.getItem("token"));
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow"
  };

  try {
    const res = await fetch(`${BOOK_QR_DETAILS}/${qrId}`, requestOptions);
    response = await res.json();
   
   
  } catch (err) {
    error = await err;
  }

  return { response, error };
}

export async function assignBookQr(qrId) {
  let response;
  let error;

  const myHeaders = new Headers();
  
  myHeaders.append("Content-Type", "application/json");

  const token = JSON.parse(localStorage.getItem("token"));
  myHeaders.append("Authorization", `Bearer ${token}`);

  const body = JSON.stringify({
    qrId
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(ASSIGN_BOOK_QR, requestOptions);
    response = await res.json();
   
   
  } catch (err) {
    error = await err;
  }

  return { response, error };
}