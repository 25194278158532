/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../providers/ContextProviders";
import { LOGIN } from "../utils/constants/paths";
import { ToastContainer } from "react-toastify";
import NavBar from "../components/NavBar";
import PopUp from "../components/PopUp";
import SideBar from "../components/SideBar";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";

const ProtectedLayout = ({ children }) => {
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [isActive, setIsActive] = useState(true);
  const [openAppModal,setOpenAppModal]=useState(isMobile)
  const { user, token } = useContext(AppContext);
  const { search } = useLocation();
  const onOPenApp = () => {
    var customUrl = "eprakashak://";
    if (search.includes("qrId")) {
      customUrl = "eprakashak://qr-details" + search;
    }

    window.location.href = customUrl;

    // setTimeout(function () {
    //   if (isActive) {
    //     window.location.href =
    //       "https://play.google.com/store/apps/details?id=com.eprakashak";
    //   }
    // }, 500);
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsActive(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  // useEffect(() => {
  //     if (!user || !token) {
  //         navigate(LOGIN)
  //     }
  // }, [user, token])

  return (
    <div className="min-h-screen flex ">
      <SideBar />
      <div className="flex flex-col flex-1 lg:pt-5 lg:pr-5 bg-thumb">
        <div className="flex-1 flex flex-col bg-primary-white ">
          <NavBar />
          <Outlet />
        </div>
      </div>

      <PopUp
        height={40}
        show={!user || !token}
        title="Login to continue"
        body="You are not logged in, Please login to continue"
        btnTitle="Login"
        onClick={() => navigate(`${LOGIN}${search ?? ""}`)}
        disableClose
      />
      <PopUp
        height={40}
        show={openAppModal}
        title="Continue on App"
        body="Use Mobile app for better user experience."
        btnTitle="Open App"
        // onClick={() =>
        //   window.open(
        //     "intent://com.eprakashak/&app_store_redirect=android#/#Intent;scheme=https;package=com.eprakashak;end"
        //   )
        // }
        onClick={onOPenApp}
        disableClose
        secondaryButton={{
          title:'Continue here',
          onClick:()=>setOpenAppModal(false)
        }}
      />

      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};

export default ProtectedLayout;
