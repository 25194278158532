const baseUrl = process.env.REACT_APP_API_URL

export const LOG_IN = `${baseUrl}all/users/login`;
export const CREATE_INDIVIUAL_USER = `${baseUrl}customer/user/`;
export const RESET_PASSWORD = `${baseUrl}customer/user/resetPassword`;
export const FORGOT_PASSWORD = `${baseUrl}all/users/forgotPassword`; // done



export const BOOK_QR_DETAILS = `${baseUrl}customer/qrCode/details`;
export const ASSIGN_BOOK_QR = `${baseUrl}customer/qrCode/details/assign`;