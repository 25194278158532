import React from "react";

const Home = () => {
  return (
    <div className="w-full  flex items-center justify-center">
      <div className=" flex  items-center justify-center ">
        <div className="w-full h-auto md:w-[660px] md:h-[415px] lg:w-[660px] lg:h-[415px] mt-3">
          <iframe
            className="w-full h-full"
            src="https://www.youtube.com/embed/DV4clTZM_VQ"
            title="ePrakashak Publisher Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Home;
