import React from 'react'
import AppRoute from './routes/AppRoute'
import 'react-toastify/dist/ReactToastify.css';
import ContextProviders from './providers/ContextProviders';
const App = () => {
  return (
    <ContextProviders>
      <div className='min-h-screen' >
        <AppRoute />
      </div>
    </ContextProviders>
  )
}

export default App