import React, { useEffect, useState } from "react";
import { LOGO_ICO } from "../utils/constants/images";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../utils/constants/paths";

const NavBar = () => {
  const [user, setUser] = useState();
  const [modals, setModals] = useState({});

  const navigate =useNavigate()

  const getUser = () => {
    const tmpUser = localStorage.getItem("user");
    if (tmpUser) setUser(JSON.parse(tmpUser));
  };

  console.log(user);
  const handleLogout = () => {
   localStorage.removeItem('user')
   localStorage.removeItem('token')
   navigate(LOGIN,{replace:true})
    setModals({})
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="w-full relative px-10 p-3 flex justify-end items-center">
      <div className="flex items-center ml-1 md:ml-6 h-12">
        <button
          className={`w-[34px] h-[34px] rounded-full ${
            user?.fullName[0] === "A" ? "bg-[#e6ad10]" : "bg-[#702963]"
          }`}
        >
          {user?.photo ? (
            <img
              className="block h-full w-full object-cover rounded-full "
              src={
                user?.local
                  ? URL.createObjectURL(user?.photo)
                  : `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/profiles/${user?.photo}`
              }
              alt="Profile"
            />
          ) : (
            <span className="text-[#ffff] text-[23px] uppercase">
              {user?.fullName[0]}
            </span>
          )}
        </button>
        <div className="flex flex-col items-center justify-center w-26 mx-2 h-full">
          <p className="text-mobile-h5 sm:text-tablet-h5 lg:text-h5 text-secondary-dark font-semibold ">
            {user?.fullName}
          </p>
          {/* <p className="text-mobile-p1 sm:text-tablet-h5 lg:text-h5 text-secondary-light">
            PID: {myPublication.clientId}
          </p> */}
        </div>
      </div>
      <button
        onClick={(e) => {
          setModals((prevState) => ({
            ...prevState,
            profile: !modals.profile
          }));
          e.stopPropagation();
        }}
        className="w-4 flex justify-center items-center"
      >
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6173 0.0104983C11.5082 0.0104982 11.9544 1.08764 11.3244 1.71761L6.7281 6.31389C6.33758 6.70441 5.70441 6.70441 5.31389 6.31389L0.717605 1.71761C0.0876402 1.08764 0.533806 0.0104984 1.42471 0.0104984L10.6173 0.0104983Z"
            fill="#737272"
          />
        </svg>
      </button>
      <Transition
        show={modals.profile ?? false}
        enter="transition-opacity duration-[200ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-[500ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
              onClick={(e) => e.stopPropagation()}
              className="py-1 z-10 absolute bg-[#ffffff] shadow-[0_4px_4px_3px_rgba(181,181,181,0.14)] top-16 right-[20px] w-52 h-[40px] border-1 border-solid border-[#e7e7e7]"
            >
              <div className="absolute -top-[18px] right-4">
                <svg
                  width="26"
                  height="18"
                  viewBox="0 0 26 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.2268 1.30174C12.8273 0.53997 13.9821 0.53997 14.5827 1.30174L25.055 14.5851C25.8307 15.5689 25.1299 17.0138 23.8771 17.0138H2.93234C1.67955 17.0138 0.978768 15.5689 1.75439 14.5851L12.2268 1.30174Z"
                    fill="white"
                    stroke="#E7E7E7"
                  />
                </svg>
              </div>

              
              <button
                onClick={handleLogout}
                className="flex items-center hover:bg-[#f0f0f0] px-5 text-mobile-h4 sm:text-tablet-h4 lg:text-h4 text-secondary-dark w-full "
              >
                <span className="mr-5">
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.5 3.26562C2.5 2.1185 3.48 1.1875 4.6875 1.1875H7.8125C8.06114 1.1875 8.2996 1.28133 8.47541 1.44836C8.65123 1.61538 8.75 1.84192 8.75 2.07812C8.75 2.31433 8.65123 2.54087 8.47541 2.70789C8.2996 2.87492 8.06114 2.96875 7.8125 2.96875H4.6875C4.60462 2.96875 4.52513 3.00003 4.46653 3.0557C4.40792 3.11138 4.375 3.18689 4.375 3.26562V15.7344C4.375 15.8982 4.515 16.0312 4.6875 16.0312H7.8125C8.06114 16.0312 8.2996 16.1251 8.47541 16.2921C8.65123 16.4591 8.75 16.6857 8.75 16.9219C8.75 17.1581 8.65123 17.3846 8.47541 17.5516C8.2996 17.7187 8.06114 17.8125 7.8125 17.8125H4.6875C4.10734 17.8125 3.55094 17.5936 3.1407 17.2038C2.73047 16.8141 2.5 16.2855 2.5 15.7344V3.26562ZM15.55 8.60938H8.4375C8.18886 8.60938 7.9504 8.70321 7.77459 8.87023C7.59877 9.03726 7.5 9.26379 7.5 9.5C7.5 9.73621 7.59877 9.96274 7.77459 10.1298C7.9504 10.2968 8.18886 10.3906 8.4375 10.3906H15.55L13.0875 12.73C12.9954 12.8115 12.9215 12.9099 12.8703 13.0191C12.819 13.1284 12.7915 13.2463 12.7893 13.3659C12.787 13.4855 12.8102 13.6042 12.8573 13.7151C12.9045 13.826 12.9747 13.9268 13.0637 14.0114C13.1527 14.0959 13.2588 14.1626 13.3755 14.2074C13.4922 14.2522 13.6173 14.2742 13.7432 14.2721C13.869 14.27 13.9932 14.2438 14.1082 14.1951C14.2232 14.1464 14.3267 14.0763 14.4125 13.9887L18.475 10.1294C18.6506 9.96238 18.7492 9.73602 18.7492 9.5C18.7492 9.26398 18.6506 9.03762 18.475 8.87062L14.4125 5.01125C14.3267 4.92375 14.2232 4.85356 14.1082 4.80488C13.9932 4.75621 13.869 4.73003 13.7432 4.72792C13.6173 4.72581 13.4922 4.74781 13.3755 4.7926C13.2588 4.8374 13.1527 4.90407 13.0637 4.98864C12.9747 5.07321 12.9045 5.17395 12.8573 5.28485C12.8102 5.39575 12.787 5.51454 12.7893 5.63412C12.7915 5.75371 12.819 5.87164 12.8703 5.98089C12.9215 6.09014 12.9954 6.18846 13.0875 6.27L15.55 8.60938Z"
                      fill="#8B8B8B"
                    />
                  </svg>
                </span>
                Log Out
              </button>
            </div>
      </Transition>
    </div>
  );
};

export default NavBar;
