import React from 'react'

const SetBooks = ({set}) => {
  return (
    <div>
    <h2 className=" lg:text-laptop-h3 xl:text-p1 text-secondary-dark font-semibold text-left pt-4 mb-4">
   List of Books in set
    </h2>
    {set?.Books?.length > 0 && (
      <div className="flex flex-wrap gap-3">
        {set.Books.map((data, index) => (
          <div
            key={data.parentId}
            className="flex w-full md:h-[134px]  items-center bg-primary-extra-light mr-6 p-2"
          >
            <div
              style={{ background: data.backgroundColor }}
              className="w-1/4 md:w-[106px] h-full mr-[11px] bg-[#A93030] flex justify-center items-center py-4"
            >
              <img
                className="shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)] h-[95px] w-[75%] object-contain"
                src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${data.imageCoverFront}`}
                alt="set"
              />
            </div>
            <div className="w-3/4 md:w-auto font-semibold">
              <h2 className="text-[303030] text-mobile-h5">{data.title}</h2>
              <h3 className="text-[#5F5D5D8F] text-mobile-p1">
                {data.Bauthors?.map((auther, index, arr) => (
                  <span>
                    {arr.indexOf(arr[index]) === arr.length - 1
                      ? auther.bookAuthorName
                      : `${auther.bookAuthorName}, `}
                  </span>
                ))}{" "}
              </h3>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
  )
}

export default SetBooks