import React, { useState } from "react";
import { Link } from "react-router-dom";
import PrimaryBtn from "../../components/buttons/PrimaryBtn";
import Input from "../../components/Input";
import { LOGO_ICO } from "../../utils/constants/images";
import { LOGIN } from "../../utils/constants/paths";
import { useLocation } from "react-router-dom";
import { handleForgotPassword } from "../../utils/scripts";
import { toast,ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import PopUp from "../../components/PopUp";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [modals, setModals] = useState({});
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const { search } = useLocation();

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoaderActive(true);
    const { response } = await handleForgotPassword(email);
    if ((await response.status) !== "fail") {
      setIsLoaderActive(false);
      setModals((prev) => ({ ...prev, success: true }));
    } else {
      setIsLoaderActive(false);
      toast.warn(response.message ?? "Something went wrong");
    }
  }

  return (
    <div className="h-screen flex ">
      {isLoaderActive&& <Loader show={isLoaderActive} />}
      <div className="flex-1 flex items-center justify-center h-full flex-col">
        <div className="p-5 flex flex-col ">
          <h1 className="mb-3 md:text-tablet-h1 lg:text-laptop-h1 xl:text-h1 text-tertiary-dark font-bold ">
            Forgot Password
          </h1>
          <p className=" w-full lg:w-[350px] mb-8 lg:mb-2 text-mobile-p1 lg:text-p1 text-secondary-light text-justify font-semibold">
            If your email address is already verified with ePrakashak, please
            enter your below
          </p>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            label="Email Address"
            containerClassName="mb-5"
            value={email}
            placeholder='Enter email address'
          />

          <PrimaryBtn onClick={handleSubmit} >Continue</PrimaryBtn>
        </div>
        <p className=" mt-5 lg:text-h4 text-mobile-h4 text-center font-semibold">
          Remember Password ?{" "}
          <Link
            to={LOGIN + search ?? ""}
            className="text-tertiary-dark border-b-2 border-b-solid border-b-primary-dark"
          >
            Login
          </Link>
        </p>
      </div>
      <div className=" hidden lg:flex flex-1  items-center justify-center border-l-8  border-l-secondary-yellow bg-primary-dark">
        <img src={LOGO_ICO} className="w-1/2 h-1/2 object-contain" />
      </div>
      <PopUp
        height={30}
        show={modals.success ?? false}
        title="Mail Sent!"
        body="Please check your Inbox for resetting the password."
        disableClose
        onClick={() => setModals((prev) => ({ ...prev, success: false }))}
        btnTitle="Okay"
      />
        <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};

export default ForgotPassword;
