import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import PrimaryBtn from "../../components/buttons/PrimaryBtn";
import Input from "../../components/Input";
import { LOGO_ICO } from "../../utils/constants/images";
import { LOGIN, SIGN_UP } from "../../utils/constants/paths";
import { useForm } from "react-hook-form";
import { handleResetPassword, signup } from "../../utils/scripts";
import DialogBox from "../../components/modals/DialogBox";
import PopUp from "../../components/modals/PopUp";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const ResetPassword = () => {
  const { meta } = useParams();
  const navigate = useNavigate();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm();

  const resetHandler = async (values) => {
    try {
      console.log(values);
      const token = meta.split("&")[0];
      const role = meta.split("&")[1];
      const { response, error } = await handleResetPassword(
        values.password,
        values.confirmPassword,
        role,
        token
      );
      if (response.status == "success") {
        setShowSuccessDialog(true);
      } else {
        toast(response?.message ? response?.message : "Something went wrong");
      }
      if (error) {
        toast("Something went wrong");
      }
    } catch (error) {
      toast("Something went wrong");
    }
  };

  return (
    <div className="h-full flex ">
      <div className="flex-1 flex items-center justify-center h-full flex-col">
        <div className="  max-w-min">
          <form
            className=" flex flex-col  lg:w-auto"
            onSubmit={handleSubmit(resetHandler)}
          >
            <h1 className="mb-3 md:text-tablet-h1 lg:text-laptop-h1 xl:text-h1 text-tertiary-dark font-bold ">
              Reset Password
            </h1>
            <div className="relative">
              <Input
                containerClassName="mt-3 "
                name="password"
                errors={errors}
                label="Password"
                register={register("password", {
                  required: "Password is required",

                  pattern: {
                    value:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must have 8 characters, one letter, one number, and one special character"
                  }
                })}
                type={showPassword ? "text" : "Password"}
                placeholder="Enter password"
              />{" "}
              <button
                className="absolute focus:outline-none  right-4 top-[75px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword((prevState) => !prevState);
                }}
                type="button"
              >
                {!showPassword ? (
                  <svg
                    data-tip="Hide Password"
                    width="27"
                    height="12"
                    viewBox="0 0 27 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="focus:outline-none"
                  >
                    <path
                      d="M17.5465 5.99788C17.5465 7.58673 15.7315 8.87475 13.4927 8.87475C11.2538 8.87475 9.43889 7.58673 9.43889 5.99788C9.43889 4.40901 11.2538 3.12101 13.4927 3.12101C15.7315 3.121 17.5465 4.40903 17.5465 5.99788ZM13.5 0.0078125C11.1819 0.0154292 8.77919 0.433446 6.50466 1.23376C4.81586 1.85246 3.17003 2.72536 1.74137 3.80278C1.03968 4.35276 0.144698 5.14913 0 5.99881C0.0171 6.73483 1.08292 7.6433 1.74137 8.19486C3.08108 9.22996 4.68405 10.0785 6.50466 10.7645C8.62576 11.527 10.9735 11.966 13.5 11.9905C15.8203 11.9827 18.2225 11.5599 20.4945 10.7645C22.1833 10.1458 23.83 9.27228 25.2586 8.19486C25.9603 7.64488 26.8553 6.8485 27 5.99881C26.9829 5.2628 25.9171 4.3543 25.2586 3.80275C23.9189 2.76765 22.3151 1.91976 20.4945 1.23373C18.3745 0.471796 16.0209 0.0357958 13.5 0.0078125ZM13.4983 1.49528C17.011 1.49528 19.8585 3.51185 19.8585 5.99946C19.8585 8.48706 17.011 10.5036 13.4983 10.5036C9.98561 10.5036 7.1381 8.48705 7.1381 5.99946C7.1381 3.51185 9.98561 1.49528 13.4983 1.49528Z"
                      fill="#ADAAAA"
                    />
                  </svg>
                ) : (
                  <svg
                    data-tip="See Password"
                    width="27"
                    height="18"
                    viewBox="0 0 27 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="focus:outline-none"
                  >
                    <path
                      d="M15.0689 3.55894C14.5538 3.51767 14.0305 3.49402 13.5 3.48814C11.1819 3.49575 8.77898 3.91342 6.50446 4.71374C4.81565 5.33244 3.17056 6.20589 1.74188 7.28331C1.04022 7.83331 0.144697 8.62968 0 9.47935C0.0171 10.2154 1.08344 11.1238 1.74188 11.6754C3.0816 12.7105 4.68385 13.559 6.50446 14.245C6.56633 14.2672 6.62843 14.2892 6.69067 14.3109L5.00155 16.4959L7.29666 17.5007L19.7035 1.50013L17.4943 0.5L15.0689 3.55894V3.55894ZM20.3077 4.65026L18.6218 6.81454C19.3974 7.56091 19.8578 8.48131 19.8578 9.47935C19.8578 11.967 17.011 13.9838 13.4983 13.9838C13.3465 13.9838 13.1993 13.9716 13.0501 13.9642L11.9344 15.3949C12.449 15.4357 12.9692 15.4654 13.5 15.4706C15.8202 15.4629 18.2218 15.0404 20.4939 14.245C22.1827 13.6263 23.8294 12.7528 25.2581 11.6754C25.9598 11.1254 26.8553 10.329 27 9.47935C26.9829 8.74333 25.9165 7.83486 25.2581 7.28329C23.9184 6.24819 22.3145 5.39974 20.4939 4.71371C20.4324 4.69164 20.3695 4.67179 20.3077 4.65026V4.65026ZM13.4984 4.97497C13.6524 4.97497 13.8052 4.97954 13.9565 4.98717L12.6497 6.66319C10.8156 6.93863 9.43945 8.09453 9.43945 9.47815C9.43945 9.82571 9.52587 10.1585 9.68499 10.4669C9.68517 10.4672 9.68481 10.4678 9.68499 10.4681L8.37486 12.149C7.59746 11.402 7.13889 10.4785 7.13889 9.47933C7.13891 6.99174 9.98568 4.97496 13.4984 4.97497V4.97497ZM17.3002 8.50889L14.3553 12.2882C16.1795 12.0076 17.5457 10.8569 17.5457 9.47815C17.5457 9.13691 17.4538 8.81249 17.3002 8.50889V8.50889Z"
                      fill="#ADAAAA"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="relative">
              <Input
                label="Confirm"
                errors={errors}
                name="confirmPassword"
                containerClassName="mt-4 mb-7"
                register={register("confirmPassword", {
                  required: "Confirm password is required",
                  validate: (value) =>
                    value === watch("password") || "Passwords do not match"
                })}
                placeholder="Re enter password"
                type={showconfirmPassword ? "text" : "Password"}
              />
              <button
                className="absolute focus:outline-none  right-4 top-[75px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShowconfirmPassword((prevState) => !prevState);
                }}
                type="button"
              >
                {!showconfirmPassword ? (
                  <svg
                    data-tip="Hide Password"
                    width="27"
                    height="12"
                    viewBox="0 0 27 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="focus:outline-none"
                  >
                    <path
                      d="M17.5465 5.99788C17.5465 7.58673 15.7315 8.87475 13.4927 8.87475C11.2538 8.87475 9.43889 7.58673 9.43889 5.99788C9.43889 4.40901 11.2538 3.12101 13.4927 3.12101C15.7315 3.121 17.5465 4.40903 17.5465 5.99788ZM13.5 0.0078125C11.1819 0.0154292 8.77919 0.433446 6.50466 1.23376C4.81586 1.85246 3.17003 2.72536 1.74137 3.80278C1.03968 4.35276 0.144698 5.14913 0 5.99881C0.0171 6.73483 1.08292 7.6433 1.74137 8.19486C3.08108 9.22996 4.68405 10.0785 6.50466 10.7645C8.62576 11.527 10.9735 11.966 13.5 11.9905C15.8203 11.9827 18.2225 11.5599 20.4945 10.7645C22.1833 10.1458 23.83 9.27228 25.2586 8.19486C25.9603 7.64488 26.8553 6.8485 27 5.99881C26.9829 5.2628 25.9171 4.3543 25.2586 3.80275C23.9189 2.76765 22.3151 1.91976 20.4945 1.23373C18.3745 0.471796 16.0209 0.0357958 13.5 0.0078125ZM13.4983 1.49528C17.011 1.49528 19.8585 3.51185 19.8585 5.99946C19.8585 8.48706 17.011 10.5036 13.4983 10.5036C9.98561 10.5036 7.1381 8.48705 7.1381 5.99946C7.1381 3.51185 9.98561 1.49528 13.4983 1.49528Z"
                      fill="#ADAAAA"
                    />
                  </svg>
                ) : (
                  <svg
                    data-tip="See Password"
                    width="27"
                    height="18"
                    viewBox="0 0 27 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="focus:outline-none"
                  >
                    <path
                      d="M15.0689 3.55894C14.5538 3.51767 14.0305 3.49402 13.5 3.48814C11.1819 3.49575 8.77898 3.91342 6.50446 4.71374C4.81565 5.33244 3.17056 6.20589 1.74188 7.28331C1.04022 7.83331 0.144697 8.62968 0 9.47935C0.0171 10.2154 1.08344 11.1238 1.74188 11.6754C3.0816 12.7105 4.68385 13.559 6.50446 14.245C6.56633 14.2672 6.62843 14.2892 6.69067 14.3109L5.00155 16.4959L7.29666 17.5007L19.7035 1.50013L17.4943 0.5L15.0689 3.55894V3.55894ZM20.3077 4.65026L18.6218 6.81454C19.3974 7.56091 19.8578 8.48131 19.8578 9.47935C19.8578 11.967 17.011 13.9838 13.4983 13.9838C13.3465 13.9838 13.1993 13.9716 13.0501 13.9642L11.9344 15.3949C12.449 15.4357 12.9692 15.4654 13.5 15.4706C15.8202 15.4629 18.2218 15.0404 20.4939 14.245C22.1827 13.6263 23.8294 12.7528 25.2581 11.6754C25.9598 11.1254 26.8553 10.329 27 9.47935C26.9829 8.74333 25.9165 7.83486 25.2581 7.28329C23.9184 6.24819 22.3145 5.39974 20.4939 4.71371C20.4324 4.69164 20.3695 4.67179 20.3077 4.65026V4.65026ZM13.4984 4.97497C13.6524 4.97497 13.8052 4.97954 13.9565 4.98717L12.6497 6.66319C10.8156 6.93863 9.43945 8.09453 9.43945 9.47815C9.43945 9.82571 9.52587 10.1585 9.68499 10.4669C9.68517 10.4672 9.68481 10.4678 9.68499 10.4681L8.37486 12.149C7.59746 11.402 7.13889 10.4785 7.13889 9.47933C7.13891 6.99174 9.98568 4.97496 13.4984 4.97497V4.97497ZM17.3002 8.50889L14.3553 12.2882C16.1795 12.0076 17.5457 10.8569 17.5457 9.47815C17.5457 9.13691 17.4538 8.81249 17.3002 8.50889V8.50889Z"
                      fill="#ADAAAA"
                    />
                  </svg>
                )}
              </button>
            </div>

            <PrimaryBtn type="submit">Reset</PrimaryBtn>
          </form>
          <p className=" mt-5 lg:text-h4 text-mobile-h4 text-center font-semibold">
            Want to login ?{" "}
            <Link
              to={LOGIN}
              className="text-tertiary-dark border-b-2 border-b-solid border-b-primary-dark"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
      <div className=" hidden lg:flex flex-1  items-center justify-center border-l-8  border-l-secondary-yellow bg-primary-dark">
        <img src={LOGO_ICO} className="w-1/2 h-1/2 object-contain" />
      </div>
      <DialogBox show={showSuccessDialog}>
        <PopUp
          height={true}
          button="Login"
          title="Password Reset Successful!"
          main="Please login using your email and new password."
          onClose={() => {
            setShowSuccessDialog(false);
            reset();
          }}
          onClick={() => {
            navigate(LOGIN);
            setShowSuccessDialog(false);
            reset();
          }}
        />
      </DialogBox>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};

export default ResetPassword;
