import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PrimaryBtn from "../../components/buttons/PrimaryBtn";
import Input from "../../components/Input";
import { LOGO_ICO } from "../../utils/constants/images";
import { LOGIN, SIGN_UP } from "../../utils/constants/paths";
import { useForm } from "react-hook-form";
import { signup } from "../../utils/scripts";
import DialogBox from "../../components/modals/DialogBox";
import PopUp from "../../components/modals/PopUp";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Loader";
const SignUp = () => {
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const { search } = useLocation();
  const navigate = useNavigate();

  const signupHandler = async (values) => {
    const publisherId = search?.includes("publisher") ? search?.split("=")[1] : "";
    const data = {
      fullName: values.name,
      email: values.email,
      mobile: values.phNo,
      publisherId
    };
    try {
      setLoading(true);
      const { response, error } = await signup(data);
      if (response.status == "success") {
        setShowSuccessDialog(true);
        setLoading(false);
      } else {
        setLoading(false);
        toast(response?.message ? response?.message : "Something went wrong");
      }
      if (error) {
        setLoading(false);
        toast("Something went wrong");
      }
    } catch (error) {}
  };
  const onNavigateToLogin = () => {
    if (search?.includes("qrId")) {
      navigate(LOGIN + search ?? "");
    } else {
      navigate(LOGIN);
    }
  };
  return (
    <>
      {loading && <Loader show={loading} />}
      <div className="h-screen flex ">
        <div className="flex-1 flex items-center justify-center h-full flex-col">
          <form
            className="p-5 flex flex-col w-full lg:w-auto"
            onSubmit={handleSubmit(signupHandler)}
          >
            <h1 className="mb-3 md:text-tablet-h1 lg:text-laptop-h1 xl:text-h1 text-tertiary-dark font-bold ">
              Create a new account
            </h1>

            <Input
              containerClassName="mt-3 "
              name="name"
              label="Name"
              errors={errors}
              register={register("name", { required: "required" })}
              placeholder="Enter name"
            />

            <Input
              containerClassName="mt-3 "
              name="phNo"
              errors={errors}
              label="Mobile Number"
              placeholder="Enter mobile number"
              register={register("phNo", {
                required: "required",
                pattern: {
                  value: /^[6-9]\d{9}$/i,
                  message: "Invalid phone number format"
                }
              })}
            />
            <Input
              type="text"
              label="Email Address"
              placeholder="Enter email"
              errors={errors}
              name="email"
              containerClassName="mb-7 mt-3"
              register={register("email", {
                required: "required",
                validate: {
                  matchPattern: (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "Email address must be a valid address"
                }
              })}
            />

            <PrimaryBtn type="submit">Register</PrimaryBtn>
          </form>
          <p className=" mt-5 lg:text-h4 text-mobile-h4 text-center font-semibold">
            Already have an account?{" "}
            <span
              onClick={onNavigateToLogin}
              className="text-tertiary-dark border-b-2 border-b-solid border-b-primary-dark cursor-pointer"
            >
              Login
            </span>
          </p>
        </div>
        <div className=" hidden lg:flex flex-1  items-center justify-center border-l-8  border-l-secondary-yellow bg-primary-dark">
          <img src={LOGO_ICO} className="w-1/2 h-1/2 object-contain" />
        </div>
        <DialogBox show={showSuccessDialog}>
          <PopUp
            height={true}
            title={"Registration Completed"}
            body="New User has been added successfully, Please check your email for re-setting password and login."
            onClose={() => {
              setShowSuccessDialog(false);
              reset();
            }}
            button="Close"
            onClick={() => {
              setShowSuccessDialog(false);
              reset();
            }}
          />
        </DialogBox>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
};

export default SignUp;
