import React from 'react'
import { LOGO_ICO } from '../utils/constants/images'

const SideBar = () => {
  return (
    <div className='p-3 hidden lg:block bg-primary-dark min-h-full' >
       <img alt="logo" src={LOGO_ICO} className='w-20 h-20 object-contain' />
    </div>
  )
}

export default SideBar