import React from 'react'
import DialogBox from './DialogeBox'

const PopUp = ({
    onClose,
    disableClose,
    onClick,
    btnTitle,
    title,
    subTitle,
    body,
    show,
secondaryButton,
    height,
    ...props
}) => {
  return (
    <DialogBox show={show}>
    <div
    {...props}
    // bottom-[60px] old
    className={`bottom-0 pb-[70%] md:pb-8 bg-primary-white flex flex-col justify-between relative w-full lg:w-pop-up  ${height ? "lg:height-fit gap-4" : " h-[463px] lg:h-pop-up"}  p-8`}
  >
    <h4 className="text-h4 text-tertiary-dark font-bold">{title}</h4>
   {subTitle&& <h5 className="text-heading font-bold">{subTitle}</h5>}
    <p className="text-secondary-light">{body}</p>
    <div className="text-center lg:text-right">
      <button
        onClick={onClick}
        className="w-full max-w-[372px] lg:w-[150px] h-[56px] bg-primary-dark text-primary-white text-mobile-h5 lg:text-h5"
      >
        {btnTitle}
      </button>
    </div>
   {secondaryButton&& <div className="text-center lg:text-right">
      <button
        onClick={secondaryButton?.onClick}
        className="w-full max-w-[372px] lg:w-[150px] h-[56px] border border-primary-dark text-primary-dark text-mobile-h5 lg:text-h5"
      >
        {secondaryButton?.title}
      </button>
    </div>}
    {!disableClose && (
      <button onClick={onClose} className="absolute top-8 right-8">
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
            fill="black"
            fillOpacity="0.53"
          />
        </svg>
      </button>
    )}
  </div>
  </DialogBox>
  )
}

export default PopUp