/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { assignBookQr, getBookQrDetails } from "../../utils/scripts";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import BookExpirationInfo from "./components/BookExpirationInfo";
import { AppContext } from "../../providers/ContextProviders";
import PopUp from "../../components/PopUp";
import PrimaryBtn from "../../components/buttons/PrimaryBtn";
import AlreadyAssignedModal from "./components/AlreadyAssignedModal";
import SetBooks from "./components/SetBooks";

const BookQrCodeDetails = () => {
  const { token } = useContext(AppContext);

  const [searchParams] = useSearchParams();
  const qrId = searchParams.get("qrId");

  const [responseData, setResponseData] = useState();
  const [isSet, setIsSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCover, setSelectedCover] = useState();
  const [seeMore, setSeeMore] = useState(false);
  const [modals, setModals] = useState({});
  const [assignedUser, setAssignedUser] = useState({});
  console.log(isSet, "isSet");
  const retrieveQrDetails = async () => {
    setLoading(true);
    const { response, error } = await getBookQrDetails(qrId);
    setLoading(false);
    if (response?.status === "success") {
      console.log(response);
      setResponseData(response.data.details);
      setIsSet(response.data.isSet);
    } else {
      // toast(response?.message??'Something went wrong')
      setModals((prev) => ({ ...prev, invalidQrId: true }));
    }
    if (error) {
      toast(error?.message);
    }
  };

  const assignBookHandler = async () => {
    console.log("hererere");
    setLoading(true);
    const { response, error } = await assignBookQr(qrId);
    setLoading(false);
    console.log(response);
    if (response?.status === "success") {
      setModals((prev) => ({ ...prev, assignSuccess: true }));
    } else {
      // toast(response?.message??'Something went wrong')
      if (response.errorCode == "406") {
        setAssignedUser(response.assignedUser);
        setModals((prev) => ({
          ...prev,
          alreadyAssigned: true
        }));
      } else {
        toast(response?.message ?? "Something went wrong");
      }
    }
    if (error) {
      toast(error?.message);
    }
  };

  useEffect(() => {
    if (token && qrId) {
      retrieveQrDetails();
    }
  }, []);
  console.log(responseData, "responseData");
  return (
    <>
      {loading ? (
        <Loader show={loading} />
      ) : (
        responseData && (
          <div className="p-5 w-full flex justify-center flex-col md:flex-row">
            <div className="hidden  md:flex  flex-col md:flex-row">
              <div className="md:w-1/3 w-full flex items-center flex-col">
                <div>
                  <img
                    className="object-cover"
                    src={`https://${
                      process.env.REACT_APP_AWS_BUCKET_NAME
                    }.s3.amazonaws.com/${isSet ? "setCovers" : "books"}/${
                      selectedCover ??
                      responseData?.[isSet ? "cover" : "imageCoverFront"]
                    }`}
                    alt="bookDetails front cover"
                  />
                  <div className="flex  flex-col lg:flex-row ml-3 lg:ml-0 lg:mt-4">
                    <div
                      className="overflow-hidden lg:ml-1 lg:mr-4 w-[58px] cursor-pointer"
                      onClick={() =>
                        setSelectedCover(
                          responseData?.[isSet ? "cover" : "imageCoverFront"]
                        )
                      }
                    >
                      <img
                        className="h-full object-cover"
                        src={`https://${
                          process.env.REACT_APP_AWS_BUCKET_NAME
                        }.s3.amazonaws.com/${isSet ? "setCovers" : "books"}/${
                          responseData?.[isSet ? "cover" : "imageCoverFront"]
                        }`}
                        alt="front cover"
                      />
                    </div>
                    {responseData?.imageCoverBack && (
                      <div
                        className="overflow-hidden lg:mr-4 w-[58px] cursor-pointer"
                        onClick={() =>
                          setSelectedCover(responseData?.imageCoverBack)
                        }
                      >
                        <img
                          className="h-full object-cover "
                          src={`https://${
                            process.env.REACT_APP_AWS_BUCKET_NAME
                          }.s3.amazonaws.com/${isSet ? "setCovers" : "books"}/${
                            responseData?.[isSet ? "cover" : "imageCoverFront"]
                          }`}
                          alt="back cover"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className=" w-full md:w-3/4 ml-2 mt-5 lg:mt-0 flex flex-col mx-2">
                <h1 className="flex items-center text-mobile-h1  lg:text-h1 text-heading text-center md:text-left font-bold lg:font-semibold">
                  {responseData?.title}
                </h1>
                {(isSet && responseData.description) ||
                  (!(
                    responseData?.shortDescription == null ||
                    responseData?.longDescription == null
                  ) && (
                    <>
                      <p className="text-tertiary-dark capitalize lg:uppercase tracking-wide font-bold lg:font-semibold text-h5 mt-4">
                        description
                      </p>
                      <p className="text-secondary-dark text-p1 my-2 lg:my-1 lg:text-h5 tracking-[0.01em] leading-6 text-left">
                        {responseData?.shortDescription ||
                          responseData?.description}
                        {seeMore && responseData.longDescription}
                        {responseData.longDescription && (
                          <span
                            onClick={() => setSeeMore((prev) => !prev)}
                            className=" ml-2 cursor-pointer text-tertiary-dark underline"
                          >
                            {!seeMore ? "Read more" : "Read less"}
                          </span>
                        )}
                      </p>
                    </>
                  ))}

                {isSet && <SetBooks set={responseData} />}

                <div className="flex-1" />
                <PrimaryBtn
                  containerClassName={"xl:w-full"}
                  onClick={assignBookHandler}
                >
                  <p>Assign</p>
                </PrimaryBtn>
              </div>
            </div>
            <div className="md:hidden">
              <div
                className={`   p-5 flex flex-col items-center justify-center rounded-lg`}
                style={{ backgroundColor: responseData.backgroundColor }}
              >
                <img
                  className="w-3/5 object-cover"
                  src={`https://${
                    process.env.REACT_APP_AWS_BUCKET_NAME
                  }.s3.amazonaws.com/${isSet ? "setCovers" : "books"}/${
                    responseData?.[isSet ? "cover" : "imageCoverFront"]
                  }`}
                  alt="bookDetails front cover"
                />
               
              </div>
              <div className="">
                  <p className="text-[18px] font-medium mt-3 text-center ">
                    {responseData.title}
                  </p>
                  <p className="text-center text-mobile-p1 text-secondary-dark">
                    {responseData.Bauthors.map((a) => a.bookAuthorName).join(
                      ", "
                    )}
                  </p>
                </div>
              <hr className=" my-5 " style={{ color: "gray" }} />
              <div>
                <p className="text-thumb  -mb-2">DESCRIPTION</p>
                <p className="text-secondary-dark text-p1 my-2 lg:my-1 lg:text-h5 tracking-[0.01em] leading-6 text-left">
                {responseData?.shortDescription ||
                          responseData?.description}
                        {seeMore && responseData.longDescription}
                        {responseData.longDescription && (
                    <span
                      onClick={() => setSeeMore((prev) => !prev)}
                      className="cursor-pointer text-tertiary-dark underline"
                    >
                      {" "}
                      {!seeMore ? "Read more" : "Read less"}
                    </span>
                  )}
                </p>
                {isSet && <SetBooks set={responseData} />}
              </div>
            </div>
            <div className=" w-full md:w-1/4 md:min-w-[300px] ">
              <BookExpirationInfo responseData={responseData} isSet={isSet} />
            </div>
            <PrimaryBtn
              containerClassName={" mt-3 xl:hidden"}
              onClick={assignBookHandler}
            >
              <p>Assign</p>
            </PrimaryBtn>
          </div>
        )
      )}
      <PopUp
        height={30}
        show={!qrId}
        title="Qr Code Not found"
        body="This Qr code is missing. Please verify and scan again."
        disableClose
        onClick={() => {}}
        btnTitle="Okay"
      />
      <PopUp
        height={30}
        show={modals.invalidQrId ?? false}
        title="Invalid Qr Code."
        body="This Qr code is invalid. Please use a valid one to continue."
        disableClose
        onClick={() => {}}
        btnTitle="Okay"
      />
      <PopUp
        height={30}
        show={modals.assignSuccess ?? false}
        title="Success"
        body="This book is assigned successfully."
        disableClose
        onClick={() => setModals((prev) => ({ ...prev, assignSuccess: false }))}
        btnTitle="Okay"
      />
      <AlreadyAssignedModal
        height={50}
        show={modals.alreadyAssigned ? true : false}
        assignedUser={assignedUser ?? {}}
        onClick={() => {
          setModals((prev) => ({ ...prev, alreadyAssigned: null }));
          setAssignedUser({});
        }}
        onClose={() => {
          setModals((prev) => ({ ...prev, alreadyAssigned: null }));
          setAssignedUser({});
        }}
        btnTitle="Okay"
      />
    </>
  );
};

export default BookQrCodeDetails;
