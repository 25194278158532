import React from "react";

const BookExpirationInfo = ({ responseData, isSet }) => {
  function calculateExpiry(expireDate, createdDate) {
    const date1 = Date.parse(expireDate);

    const date2 = new Date();

    const difference = date1 - date2;

    const day = Math.round(difference / (3600000 * 24));
    const yearsDecimal = (day / 365);
    const years = Number.isInteger(yearsDecimal)?yearsDecimal:yearsDecimal.toFixed(1)
    const percent = day / (responseData.validity * 365);

    return { day, percent: percent * 100, years };
  }
  if (!responseData) return null;
  return (
    <div className="mt-3 md:mt-0 ">
      <div className=" p-2 border-1 border-solid border-primary-dark-20 w-full h-fit -48 mb-8">
        <div className=" px-4">
          <h2 className="text-heading font-bold text-h5 md:text-h4 text-left mb-2">
            Expiration Date
          </h2>
          <div className="flex justify-between items-center mb-2">
            <p className="flex-1 text-secondary-light text-semibold text-h5 mb-2">
              {/* {book?.title} */}
            </p>
            <p className="text-secondary-dark text-semibold text-h5 ">
              {
                calculateExpiry(
                  responseData.expiresOn,
                  responseData.createdAt,
                  responseData.validity
                ).years
              }{" "}
              years
            </p>
          </div>
          <div className=" h-[18px] bg-primary-light mb-6 rounded-full">
            <div
              className="h-full bg-primary-dark rounded"
              style={{
                rounded: "50px",
                width:
                  calculateExpiry(
                    responseData.expiresOn,
                    responseData.createdAt,
                    responseData.validity
                  ).percent + "%"
              }}
            ></div>
          </div>
        </div>
        {/* {expired ? (""
              // <button className="text-heading font-semibold text-p1 h-[41px] w-full border-1 border-solid border-primary-dark-20">
              //   Reactivate
              // </button>
            ) : (
              <button className="text-heading font-semibold text-p1 h-[41px] w-full border-1 border-solid border-primary-dark-20">
                Renew
              </button>
            )} */}
      </div>

      <div className="  p-4 border-1 border-solid border-primary-dark-20 w-full ">
        <h2 classNaame="text-heading font-bold text-h4 text-left mb-2">
          Details
        </h2>
        <div className="my-4 mb-0 flex flex-wrap ">
          {/* <div className="w-1/2"> */}
          <div className="mb-4 w-1/2">
            <h3 className="text-h5 text-secondary-dark font-semibold">
              {isSet ? "No. of books" : "Edition"}
            </h3>
            <p className="text-h4 text-tertiary-dark font-semibold">
              {isSet ? responseData?.Books?.length : responseData?.edition}
            </p>
          </div>

          {/* <div className="mb-4 w-1/2">
                        <h3 className="text-h5 text-secondary-dark font-semibold">
                          Book ID
                        </h3>
                        <p className="text-ellipsis overflow-hidden whitespace-nowrap mr-6 text-h4 text-tertiary-dark font-semibold">
                          {book?.id}
                        </p>
                      </div> */}

          {!isSet && (
            <div className="mb-4 w-1/2">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                Page Count
              </h3>
              <p className="text-h4 text-tertiary-dark font-semibold">
                {responseData?.countOfPages} Pages
              </p>
            </div>
          )}

          {!isSet && (
            <div className="mb-4 w-1/2">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                Print
              </h3>
              <p className="text-h4 text-tertiary-dark font-semibold">
                {responseData?.allowPrint ? "Yes" : "No"}
              </p>
            </div>
          )}
          {responseData?.customFieldName && (
            <div className="mb-4 w-1/2">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                {responseData?.customFieldName}
              </h3>
              <p className="text-h4 text-tertiary-dark font-semibold">
                {responseData?.customFieldContents}
              </p>
            </div>
          )}

          {responseData?.ISBN && (
            <div className="mb-4 w-1/2">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                ISBN NO.
              </h3>
              <p className="text-h4 text-tertiary-dark font-semibold">
                {responseData?.ISBN}
              </p>
            </div>
          )}
          {/* </div> */}

          {/* <div className="w-1/2"> */}
          <div className="mb-4 w-1/2">
            <h3 className="text-h5 text-secondary-dark font-semibold">
              Category
            </h3>
            <p className="text-h4 text-tertiary-dark font-semibold">
              {responseData?.Bcategories?.map((elm, index) => (
                <p
                  key={index}
                  className=" capitalize text-h4 text-tertiary-dark font-semibold"
                >
                  {elm?.bookCategoryName}
                </p>
              ))}
            </p>
          </div>

          <div className="mb-4 w-1/2">
            <h3 className="text-h5 text-secondary-dark font-semibold">
              Author
            </h3>
            <p className="text-h4 text-tertiary-dark font-semibold">
              {responseData?.Bauthors?.map((elm, index) => (
                <p
                  key={index}
                  className="text-h4 text-tertiary-dark font-semibold"
                >
                  {elm?.bookAuthorName}
                </p>
              ))}
            </p>
          </div>

          <div className="mb-4 w-1/2">
            <h3 className="text-h5 text-secondary-dark font-semibold">
              Publishers
            </h3>
            {responseData?.Bpublications?.map((elm, index) => (
              <p
                key={index}
                className="text-h4 text-tertiary-dark font-semibold"
              >
                {elm?.bookPublicationName}
              </p>
            ))}
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default BookExpirationInfo;
