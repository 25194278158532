export function maskString(str, type = "text") {
  if (!str) return "";
  // Get the length of the string
  var length = str.length;

  // If the length is less than or equal to 4, return the string as it is
  if (length <= 4) {
    return str;
  }
  let maskedString = "";
  if (type == "text") {
    maskedString = "*".repeat(length - 4) + str.substring(length - 4);
  } else if (type == "email") {
    const splitArray = str.split("@");
    const sec1 = splitArray[0];
    let sec1Masked = "";
    if (sec1.length <= 2) {
      sec1Masked = sec1;
    } else {
      sec1Masked =
        sec1[0] + sec1.slice(1, -1).replace(/./g, "*") + sec1[sec1.length - 1];
    }
    maskedString = sec1Masked + "@" + splitArray[1];
  }
  // Otherwise, replace all characters except the last 4 with stars
  return maskedString;
}
