import React from 'react'

const PrimaryBtn = ({ containerClassName, onClick, children, ...restProps }) => {
    return (
        <button
            className={`hover:border-2 hover:border-primary-dark hover:border-solid text-center bg-primary-dark md:w-[300px] xl:w-354 h-14 text-primary-white ${containerClassName}`}
            onClick={onClick}
            {...restProps}
        >
            {children}
        </button>

    )
}

export default PrimaryBtn



